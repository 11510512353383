import { forwardRef } from 'react';
import { ISvgProps } from './Icon.type';

export const TextAvabot = forwardRef<SVGSVGElement, ISvgProps>(
  function CopyContentIcon(props, ref) {
    return (
      <svg
        ref={ref}
        {...props}
        width="1920"
        height="291"
        viewBox="0 0 1920 291"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.1">
          <path
            d="M-18.5262 44.0553C2.01199 27.5908 31.3002 19.3585 69.3384 19.3585C107.377 19.3585 136.726 27.5908 157.385 44.0553C178.045 60.5199 188.375 81.6885 188.375 107.561V202.379C188.375 228.448 178.106 249.666 157.568 266.033C137.029 282.399 107.62 290.582 69.3384 290.582C31.3002 290.582 2.01199 282.448 -18.5262 266.18C-39.0644 249.813 -49.3335 228.546 -49.3335 202.379V107.561C-49.3335 81.6885 -39.0644 60.5199 -18.5262 44.0553ZM141.344 201.497V108.149C141.344 90.9008 135.632 77.1804 124.208 66.9881C112.785 56.6977 94.4946 51.5526 69.3384 51.5526C44.1821 51.5526 25.8922 56.6977 14.4686 66.9881C3.04498 77.1804 -2.66683 90.9008 -2.66683 108.149V201.497C-2.66683 218.942 3.04498 232.809 14.4686 243.1C25.8922 253.292 44.1821 258.388 69.3384 258.388C94.4946 258.388 112.785 253.292 124.208 243.1C135.632 232.809 141.344 218.942 141.344 201.497ZM125.302 166.363V206.349C125.302 219.187 120.441 229.134 110.719 236.191C100.996 243.149 87.203 246.628 69.3384 246.628C51.1092 246.628 36.8905 242.806 26.6821 235.162C16.5953 227.419 11.5519 215.806 11.5519 200.321V109.766C11.5519 94.282 16.6561 82.6686 26.8644 74.9263C37.0728 67.1841 51.3523 63.3129 69.703 63.3129C87.3245 63.3129 100.996 66.8411 110.719 73.8973C120.441 80.9535 125.302 90.9008 125.302 103.739V143.137H77.5415V101.828C77.5415 96.634 74.8071 94.0369 69.3384 94.0369C63.8696 94.0369 61.1353 96.732 61.1353 102.122V207.525C61.1353 213.111 63.8696 215.953 69.3384 216.051C74.8071 216.051 77.5415 213.307 77.5415 207.819V166.363H125.302Z"
            fill="#FF2497"
          />
          <path
            d="M410.588 244.864V287.936H258.375V271.619C258.375 255.154 261.292 239.229 267.125 223.842C272.958 208.358 280.007 195.029 288.271 183.857C296.656 172.587 305.042 162.002 313.427 152.104C321.934 142.108 329.104 132.356 334.937 122.85C340.892 113.246 343.87 104.425 343.87 96.389V52.8756C343.87 45.8194 340.467 42.2913 333.661 42.2913C326.734 42.2913 323.271 45.8194 323.271 52.8756V108.149H260.198V54.6397C260.198 37.7831 266.699 24.6507 279.703 15.2424C292.828 5.73607 311.969 0.98291 337.125 0.98291C360.215 0.98291 378.08 5.19705 390.719 13.6253C403.479 22.0536 409.859 33.912 409.859 49.2005V88.3038C409.859 100.162 407.307 112.462 402.203 125.202C397.099 137.942 390.84 149.605 383.427 160.189C376.135 170.675 368.844 180.917 361.552 190.913C354.26 200.811 348.062 210.465 342.958 219.873C337.854 229.281 335.302 237.612 335.302 244.864H410.588Z"
            fill="#FF2497"
          />
          <path
            d="M443.401 17.1534C456.526 6.37309 475.423 0.98291 500.094 0.98291C524.764 0.98291 543.601 6.37309 556.604 17.1534C569.729 27.8358 576.292 42.8303 576.292 62.1369V229.575C576.292 248.882 569.729 263.876 556.604 274.559C543.601 285.241 524.642 290.582 499.729 290.582C475.302 290.582 456.526 285.241 443.401 274.559C430.397 263.779 423.896 248.784 423.896 229.575V62.1369C423.896 42.8303 430.397 27.8358 443.401 17.1534ZM510.12 238.837V52.8756C510.12 45.8194 506.778 42.2913 500.094 42.2913C493.41 42.2913 490.068 45.8194 490.068 52.8756V238.837C490.068 245.893 493.41 249.421 500.094 249.421C506.778 249.421 510.12 245.893 510.12 238.837Z"
            fill="#FF2497"
          />
          <path
            d="M742.359 244.864V287.936H590.146V271.619C590.146 255.154 593.062 239.229 598.896 223.842C604.729 208.358 611.778 195.029 620.042 183.857C628.427 172.587 636.812 162.002 645.198 152.104C653.705 142.108 660.875 132.356 666.708 122.85C672.663 113.246 675.641 104.425 675.641 96.389V52.8756C675.641 45.8194 672.238 42.2913 665.432 42.2913C658.505 42.2913 655.042 45.8194 655.042 52.8756V108.149H591.969V54.6397C591.969 37.7831 598.47 24.6507 611.474 15.2424C624.599 5.73607 643.739 0.98291 668.896 0.98291C691.986 0.98291 709.851 5.19705 722.489 13.6253C735.25 22.0536 741.63 33.912 741.63 49.2005V88.3038C741.63 100.162 739.078 112.462 733.974 125.202C728.87 137.942 722.611 149.605 715.198 160.189C707.906 170.675 700.614 180.917 693.323 190.913C686.031 200.811 679.833 210.465 674.729 219.873C669.625 229.281 667.073 237.612 667.073 244.864H742.359Z"
            fill="#FF2497"
          />
          <path
            d="M868.87 136.521C892.568 139.755 904.356 152.692 904.234 175.331V238.837C904.234 254.321 897.672 266.768 884.547 276.176C871.543 285.584 853.01 290.288 828.948 290.288C778.635 290.288 753.479 271.619 753.479 234.28V167.833H817.281V238.543C817.281 245.207 820.745 248.539 827.672 248.539C834.356 248.539 837.759 244.913 837.88 237.661V171.361C837.88 161.953 833.262 157.249 824.026 157.249H794.495L785.562 119.028H822.932C831.925 119.028 836.361 114.422 836.239 105.209V52.1406C836.239 45.4764 832.897 42.1933 826.213 42.2913C819.772 42.2913 816.552 45.6724 816.552 52.4346V110.648H754.208V52.4346C754.208 35.9701 760.467 23.2786 772.984 14.3603C785.623 5.44206 804.642 0.98291 830.042 0.98291C851.43 0.98291 868.627 5.19705 881.63 13.6253C894.755 22.0536 901.318 33.422 901.318 47.7304V96.095C901.318 107.561 898.948 116.333 894.208 122.409C889.469 128.387 881.022 132.503 868.87 134.757V136.521Z"
            fill="#FF2497"
          />
          <path
            d="M1072.85 287.936L1069.21 231.339H1041.14L1037.49 287.936H966.943L1000.85 3.48199H1110.95L1143.4 287.936H1072.85ZM1044.23 187.091H1066.66L1060.28 89.7738L1057.18 53.7576H1053.17L1050.61 89.7738L1044.23 187.091Z"
            fill="#FF2497"
          />
          <path
            d="M1234.73 3.48199H1304.18L1270.82 287.936H1166.73L1133.56 3.48199H1203.74L1214.49 176.507L1217.96 226.488H1222.33L1225.43 176.507L1234.73 3.48199Z"
            fill="#FF2497"
          />
          <path
            d="M1400.25 287.936L1396.6 231.339H1368.53L1364.89 287.936H1294.34L1328.24 3.48199H1438.35L1470.8 287.936H1400.25ZM1371.63 187.091H1394.05L1387.67 89.7738L1384.57 53.7576H1380.56L1378.01 89.7738L1371.63 187.091Z"
            fill="#FF2497"
          />
          <path
            d="M1604.42 142.843C1628.36 148.037 1640.33 161.267 1640.33 182.534V238.837C1640.33 254.125 1634.19 266.131 1621.92 274.853C1609.76 283.575 1592.51 287.936 1570.15 287.936H1479.73V3.48199H1573.06C1592.75 3.48199 1608.43 7.25512 1620.09 14.8014C1631.88 22.2496 1637.78 33.324 1637.78 48.0244V101.828C1637.78 122.899 1626.66 135.982 1604.42 141.078V142.843ZM1557.75 47.1424H1548.09V123.585H1558.11C1566.38 123.585 1570.51 119.861 1570.51 112.413V58.4618C1570.51 50.9155 1566.26 47.1424 1557.75 47.1424ZM1557.02 161.806H1548.09V244.276H1557.02C1566.99 244.276 1571.97 239.768 1571.97 230.751V175.037C1571.97 166.216 1566.99 161.806 1557.02 161.806Z"
            fill="#FF2497"
          />
          <path
            d="M1734.57 0.98291C1761.67 0.98291 1782.03 6.56909 1795.64 17.7415C1809.25 28.9138 1816.06 46.2114 1816.06 69.6342V221.637C1816.06 267.601 1788.9 290.582 1734.57 290.582C1680.86 290.582 1654 267.601 1654 221.637V69.6342C1654 23.8667 1680.86 0.98291 1734.57 0.98291ZM1746.24 235.309V56.4037C1746.24 48.9555 1742.47 45.2314 1734.94 45.2314C1727.52 45.2314 1723.82 48.9555 1723.82 56.4037V235.309C1723.82 242.757 1727.52 246.481 1734.94 246.481C1742.47 246.481 1746.24 242.757 1746.24 235.309Z"
            fill="#FF2497"
          />
          <path
            d="M1970.09 3.48199V49.7885H1931.27V287.936H1862.91V49.7885H1823.71V3.48199H1970.09Z"
            fill="#FF2497"
          />
        </g>
      </svg>
    );
  },
);
