import React from 'react';
import Marquee from 'react-fast-marquee';
import { IconCategory } from 'components/icons';

export const BannerRoadmap = () => {
  return (
    <div className="footer-advertisement advertisement">
      <div className="advertisement__pink">
        <Marquee
          style={{
            height: '100%',
          }}
        >
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Private Tx</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Limit Orders</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Method Sniper</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Account Abstraction</div>
            </div>
          </div>
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Private Tx</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Limit Orders</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Method Sniper</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Account Abstraction</div>
            </div>
          </div>
          <div className="advertisement__list">
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Private Tx</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Limit Orders</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Method Sniper</div>
            </div>
            <div className="advertisement__item">
              <IconCategory />
              <div className="advertisement__name">Account Abstraction</div>
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  );
};
