import React, { CSSProperties, FC, ReactNode } from 'react';

interface SkewRunnerProps {
  bgTop?: string;
  bgBottom?: string;
  offset?: string | number;
  rotate?: string;
  heightContain?: string;
  children?: ReactNode;
  zIndex?: number;
  direction?: 'ltr' | 'rtl';
}

export const SkewRunner: FC<SkewRunnerProps> = ({
  bgTop,
  bgBottom,
  offset,
  rotate,
  heightContain,
  zIndex,
  direction = 'rtl',
  children,
}) => {
  return (
    <div
      className="skew-runner"
      style={
        {
          '--bgTop': bgTop || 'transparent',
          '--bgBottom': bgBottom || 'transparent',
          '--heightContain': heightContain || '80px',
          '--rotate': rotate || '0deg',
          '--offset': offset || 0,
          '--zIndex': zIndex || 10,
          '--direction': direction === 'rtl' ? 1 : -1,
        } as CSSProperties
      }
    >
      <div className="skew-runner__container">{children}</div>
    </div>
  );
};
